.product-slide{
  .slick-slide {
    margin: 0 1em;

    img{
      width: 100%;
    }

    .old-price{
      opacity: 0.6;
      font-size: 80%;
    }
  }
}
