.pagination {
  text-align: center;

  .page, .next, .last {
    line-height: 45px;
    border: 0px solid var(--border-color-11);
    font-weight: 600;
    border-radius: 100%;
    background-color: #F6F6F6;
    font-size: 13px;
    margin: 10px 5px;
    text-align: center;
    display: inline-block;
    width: 45px;
  }
}