.product-list{
  background-color: #fafafa;
  text-align: center;
}
.order-section{
  border: 1px solid #ddd;

  .header{
    background-color: #fafafa;
    padding: 1em 2em;
  }
}
.submit-order{
  width: 100%;
  background-color: #dd1d26;
  color: #fff;
  padding: 1em;
}
.invoice-field{
  display: none;
}