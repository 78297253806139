.breadcrumb{
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 425px) {
  .breadcrumb{
    .path{
      font-size: 10px;
    }
  }
}