.success-message {
  text-align: center;
  background-color: #009212;
  color: #fff;
  padding: 1em;
}
.error-message {
  text-align: center;
  background-color: #920000;
  color: #fff;
  padding: 1em;
}