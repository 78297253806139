.fixed-filter-button {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  padding: 14px 19px;
  border: 1px solid;
  border-radius: 2rem;
  display: block;
  background-color: #fff;
  cursor: pointer;
  z-index: 9980;
}