#search-menu-wrapper {
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  input{
    width: 30vw
  }
}