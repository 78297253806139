.membership-button {
  background-color: transparent;
  border: 1px solid #00000052;
  width: 100%;
  padding: 0.5em;
  font-weight: bold;
  cursor: pointer;
  display: block;
  text-align: center;
}
.register-success {
  text-align: center;
  padding: 1em 0em;
  background-color: #007e0f;
  color: #fff;
  font-weight: bold;
  margin-top: 1em;
}