#product{

  #images{
    #gallery-nav {
      text-align: right;

      .slick-list{
        padding: 0px!important;
      }
      .slick-slide {
        margin: 0.2em 0em;
      }
      .slick-current{
        img{
          border: 1px solid #aec39f;
        }
      }
    }

    #main-image{
      img{
        width: 100%;
        max-height: 529px;
      }
    }
  }

  #details{
    #title {
      font-size: 30px;
      font-weight: 500;
      color: #000;
    }

    #price{
      color: #aec39f;
      font-size: 24px;
      font-weight: 500;
    }

    #old-price{
      opacity: 0.6;
    }

    #quantity-wrapper {
      border: 1px solid rgba(0,0,0,.15);

      .q-item {
        font-size: 20px;
        font-weight: 400;
      }

      #quantity {
        text-align: center;
        display: block;
        width: 75px;
        padding: 0.5em;
      }

      #plus, #minus{
        cursor: pointer;
        font-size: 0.5em;
      }

      #plus {
        padding: 1em 0.6em;
      }

      #minus {
        padding: 1em 0.6em;
      }
    }

    #add-to-cart {
      background-color: #222;
      color: #fff;
      padding: 1rem;
      cursor: pointer
    }

    #options{

      .option{

        .item{
          cursor: pointer;

          span{
            padding: 0.2em;
          }

          img{
            padding: 0.1em;
          }
        }

        .item.disabled{
          border: 0px!important;
          cursor: default!important;
          background-color: #8f8f8f!important;
          color: #fff!important;
          opacity: 0.5;

          &:hover{
            color: #fff!important;
            background-color: #8f8f8f!important;
          }
        }

        .active{
          border: 2px solid #000;
        }
      }
    }
  }
}

.tabs{
  .tab-header {
    font-size: 1em;
    cursor: pointer;
    text-align: center;
  }
  .tab-content{
    width: 100%;
    margin-top: 1em;
  }
}
