@import "alerts";
@import "loader";

.error {
  color: #ff0000;
  font-weight: bold;
}
.error-message {
  text-align: center;
  background-color: #ff7878;
  padding: 1em 0em;
  border: 1px solid #c50000;
  color: #fff;
  font-weight: bold;
}
img{
  display: unset
}