@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
//@import "~@tailwindcss/custom-forms/dist/custom-forms";

@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300&display=swap');

/* vendor */
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import "~ez-plus/css/jquery.ez-plus";
@import "vendor/slick";
@import "vendor/glassstyle.min";
@import "product-slide";
@import "pagination";
@import "search";
@import "membership";

/* pages */
@import "common";
@import "breadcrumbs";
@import "product";
@import "header";
@import "checkout";
@import "categories";
@import "footer";

body{
  color: #666;
  font-family: 'Source Serif Pro', serif;
}
h1, h2, h3, h4, h5{
  color: #222;
}
i{
  color: #000000;
}
img{
  display: unset;
}
.slick-slide {
  img {
    display: unset;
  }
}
#home-slider{
  .slick-slide {
    img{
      display: unset;
    }
  }
}
.fixed-black-background {
  position: fixed;
  background-color: #00000066;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9990;
}
p {
  word-break: break-word;
  white-space: pre-wrap;
}

a{
  &:hover{
    color: #aec39f
  }
}
.search-items{
  max-height: 80vh;
  overflow-y: auto;
}
.page.current {
  background-color: #000;
  color: #fff;
}
.grid-display.active, .line-display.active{
  color: #000!important;
  cursor: pointer;
}
.first-image, .second-image{
  transition: all 300ms ease-in-out;
}
.first-image:hover{
  opacity: 0
}
.first-image:hover + .second-image{
  opacity: 0.5
}
#top-banners{
  .banner-title{
    background: #ffffff73;
    padding: 0.5rem;
    border-radius: 10px;
  }
}
.second-image{
  opacity: 0;
}
.user-warning {
  text-align: center;
  background-color: #e5e90899;
  padding: 1em;
  margin-top: 1em;
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid #af7900;
}
.slider-arrow-left{
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background: #fff;
  width: 90px;
  height: calc(90px - 10px);
  display: block;
  z-index: 100;

  i{
    font-size: 34px;
    display: block;
    line-height: calc(90px - 10px);
    text-align: center;
    color: #cecece;
    transition: all 200ms ease-in-out;

    &:hover{
      color: #aec39f
    }
  }
}


.slider-arrow-right{
  position: absolute;
  left: 19px;
  top: 41%;
  cursor: pointer;
  display: block;
  z-index: 100;
  border-radius: 50%;

  i{
    font-size: 34px;
    display: block;
    line-height: calc(90px - 10px);
    text-align: center;
    color: #cecece;
    transition: all 200ms ease-in-out;

    &:hover{
      color: #fff
    }
  }
}
.save-button {
  padding: 0.5em 0.5em;
  border: 1px solid;
  background-color: transparent;
  font-weight: bold;
}
.success-icon{
  color: #b6d554;
}
.arrow-right.slick-arrow {
  position: absolute;
  top: 40%;
  left: 5px;
  background-color: #fff;
  z-index: 9998;
  border: 1px solid #0003;
  border-radius: 50px;
  width: 60px;
  font-size: 1.5em;
  height: 60px;
  padding: 13px 20px;
}
.arrow-left.slick-arrow {
  position: absolute;
  top: 40%;
  right: 5px;
  background-color: #fff;
  z-index: 9998;
  border: 1px solid #0003;
  border-radius: 50px;
  width: 60px;
  font-size: 1.5em;
  height: 60px;
  padding: 14px 23px;
}