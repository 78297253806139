#cart{
  .price{
    color: #aec39f;
  }

  #count {
    font-size: 12px;
    font-weight: 600;
    height: 20px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 100%;
    right: 6px;
    top: -7px;
    background-color: #aec39f;
    position: absolute;
    color: #fff;
  }
}