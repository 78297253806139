@import "cart";
@import "mobile-menu";

#top{
  position: relative;

  .main-menu-categories {

    .main-menu-item{
      position: static;

      & > :hover{
        font-weight: bold;
        letter-spacing: 0;
      }
    }

    .main-menu-item {

      &:hover > .sub-menu {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .sub-menu {
    background-color: #fff;
    position: absolute;
    padding: 1em;
    z-index: 9999;
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.06);
    visibility: hidden;
    opacity: 0;
    min-width: 200px;
    left: 20%;
    right: auto;
    margin: auto;
    top: 100%;
    transition: all 300ms ease-in-out;

    .second-level {
      font-weight: bold;
      color: #000;
    }
  }
}

