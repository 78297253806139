@import "mobile-filters";

.filters, .mobile-filters {
  .options {
    .option {

      .image{
        width: 100%;
        height: 100%;
      }

      .item {
        .title{
          cursor: pointer;
          text-align:center;
        }
      }

      .active {
        color: #fff;
        background-color: #aec39f;

        img{
          border: 1px solid #aec39f;
        }
      }
    }
  }
}
.fixed-filters-wrapper {
  position: fixed;
  z-index: 99985;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  padding: 1rem;
  overflow-y: auto;
  display: none;
}
.clear-filters {
  background-color: #aec39f;
  color: #fff;
  width: 100%;
  padding: 1rem 0rem;
  display: block;
  text-align: center;

  &:hover{
    color: #fff;
  }
}
.category-products{
  img{
    height: fit-content;
  }

  .availability-tag{
    box-shadow: 1px 1px 3px 1px #5d5d5d63;
  }
}
